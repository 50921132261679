



























































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import './scss/PopupFilters.scss';

@Component({
    name: 'PopupFilters',
    components: {
        FiltersSelect: () => import('@components/FiltersSelect/FiltersSelect.vue'),
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        FiltersCheckboxes: () => import('@components/FiltersCheckboxes/FiltersCheckboxes.vue'),
        FiltersDateRange: () => import('@components/FiltersDateRange/FiltersDateRange.vue'),
        FiltersSelected: () => import('@components/FiltersSelected/FiltersSelected.vue'),
        BaseCheckbox: () => import('@components/BaseCheckbox/BaseCheckbox.vue'),
    }
})

export default class PopupFilters extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
        filtersCheckboxes: HTMLFormElement[],
        filtersDateRanges: HTMLFormElement[],
        filtersSelect: HTMLFormElement[],
    }

    @Prop({}) popupData!: any[];
    @Prop({}) count!: number | null;
    @Prop({}) cancelBtnText!: string;

    get textActionSuccess() {
        return `Сохранить (${this.count})`;
    }

    openPopup() {
        this.$refs.basePopup.openPopup();
    }

    closePopup() {
        this.$refs.basePopup.closePopup();
    }

    resetFilters() {
        const checkboxesFilters = this.$refs.filtersCheckboxes;
        const dateRangesFilters = this.$refs.filtersDateRanges;
        const selectFilters = this.$refs.filtersSelect;
        if (checkboxesFilters) {
            for (const item of checkboxesFilters) {
                item.resetSelected();
            }
        }
        if (dateRangesFilters) {
            for (const item of dateRangesFilters) {
                item.resetDates();
            }
        }
        if (selectFilters) {
            for (const item of selectFilters) {
                item.resetSelected();
            }
        }
    }

    changeSelectedData(data: { section: string, selected: string[] }) {
        const checkboxesFilters = this.$refs.filtersCheckboxes;
        const selectFilters = this.$refs.filtersSelect;
        if (checkboxesFilters) {
            for (const item of checkboxesFilters) {
                item.changeData(data);
            }
        }
        if (selectFilters) {
            for (const item of selectFilters) {
                item.changeData(data);
            }
        }
    }

    changeSelectedDataDate(data: { section: string, selected: string, dateRange: string }) {
        const dateRangesFilters = this.$refs.filtersDateRanges;
        if (dateRangesFilters) {
            for (const item of dateRangesFilters) {
                item.changeDataDate(data);
            }
        }
    }
}
